import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import store from './app/store'
import { Provider } from 'react-redux'
import '../styles/App.css'
import Game from './components/Game.js'
import StartScreen from './components/startScreen'

const App = () => {

  useEffect(() => {
    document.title = 'Alien Invaders';
  }, []);

  const gameStarted = useSelector(state => state.game.gameStarted)

  return (
    <div className="App">
      <Provider store={store}>
        {gameStarted ? 
          <Game/>
          :
          <StartScreen/>
        }
      </Provider>
    </div>
  );
}

export default App;

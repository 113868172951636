import React, { useCallback } from 'react'
import { Text, Graphics } from '@pixi/react';

const Counter = (props) => {

    const width = 200;
    const height = 50;
    const draw = useCallback((g) => {
        g.clear();
        g.lineStyle(2, '#a9a9a9', 1);
        g.beginFill('353535', 0.25);
        g.drawRoundedRect(props.x, props.y, width, height, 25);
        g.endFill();
      }, []);

    const graphics = (
        <Graphics draw={draw}>
            <Text text={props.value}
            anchor={0.5}
            x={(props.x + width/2)}
            y={(props.y + height/2)}
            style={({
                align: 'center',
                fontFamily: '"Source Sans Pro", Helvetica, sans-serif',
                fontSize: 15,
                fontWeight: '400',
                fill: ['#ff0000', '#a20000'], // gradient
                stroke: '#870303',
                strokeThickness: 2,
                dropShadow: true,
                dropShadowColor: '#8f0000',
                dropShadowBlur: 2,
                dropShadowAngle: Math.PI / 6,
                dropShadowDistance: 3,
                letterSpacing: 5,
                wordWrap: true,
                wordWrapWidth: 440,
                })}/>
        </Graphics>
        )

    return (graphics)

}

export default Counter
import React, {  useRef } from 'react'
import { Sprite, useTick, Container, Text } from '@pixi/react';
import { useDispatch, useSelector } from 'react-redux'
import green from '../../imgs/green.png'
import yellow from '../../imgs/yellow.png'
import red from '../../imgs/red.png'
import { updateEnemyPosition } from '../features/game'

const SpaceInvader = (props) => {
    const dispatch = useDispatch()
    const obj = props.object;
    const iter = useRef(0);
    const gameSpeed = useSelector((state) => state.game.gamespeed)
    const gameLost = useSelector(state => state.game.gameLost)

    useTick((delta) => {
        // if(!gameLost && !obj.upgrade) {
        //     const prevVal = Math.sin(iter.current/5)*0.8;
        //     const i = (iter.current += 0.15 * gameSpeed * delta);
        //     const sine = Math.sin(i/5)*0.8
        //     const yUpdate = (prevVal < 0 && sine > prevVal) || (prevVal > 0 && sine < prevVal) ? (((gameSpeed - 1)/3) + 1/5): 0
        //     dispatch(updateEnemyPosition({
        //         'x': sine,
        //         'y': yUpdate,
        //         'index': props.index
        //     }))
        // }
    });

    if (obj.upgrade === true) {
        const sprite = <Sprite x={0}j y={0} anchor={{ x: 0.5, y: 0.5 }} image={obj.type === 'green' ? green : obj.type === 'yellow' ? yellow : red} width={obj.size} height={obj.size}/>
        const container = (
        <Container position={[obj.x,obj.y]}>
            <Text text={`${obj.cost} Creds`}
            anchor={0.5}
            x={0}
            y={0 - 35}
            style={({
                align: 'center',
                fontFamily: '"Source Sans Pro", Helvetica, sans-serif',
                fontSize: 20,
                fontWeight: '400',
                fill: '#ffffff',
                stroke: '#870303',
                strokeThickness: 2,
                dropShadow: true,
                dropShadowColor: '#8f0000',
                dropShadowBlur: 2,
                dropShadowAngle: Math.PI / 6,
                dropShadowDistance: 3,
                letterSpacing: 5,
                wordWrap: true,
                wordWrapWidth: 440,
                })}/>
            {sprite}
            <Text text={obj.text}
            anchor={0.5}
            x={0}
            y={0 + 35}
            style={({
                align: 'center',
                fontFamily: '"Source Sans Pro", Helvetica, sans-serif',
                fontSize: 20,
                fontWeight: '400',
                fill: '#ffffff',
                stroke: '#870303',
                strokeThickness: 2,
                dropShadow: true,
                dropShadowColor: '#8f0000',
                dropShadowBlur: 2,
                dropShadowAngle: Math.PI / 6,
                dropShadowDistance: 3,
                letterSpacing: 5,
                wordWrap: true,
                wordWrapWidth: 440,
                })}/>
        </Container>
        )
        return (container)
        
    } else {
        
        const sprite = <Sprite x={obj.x} y={obj.y} anchor={{ x: 0.5, y: 0.5 }} image={obj.type === 'green' ? green : obj.type === 'yellow' ? yellow : red} width={obj.size} height={obj.size}/>

        return (sprite)
    }

    

}

export default SpaceInvader
import React from 'react'
import { Sprite, useTick } from '@pixi/react';
import { useDispatch, useSelector } from 'react-redux'
import { updateBulletPosition, deleteBullet } from '../features/game'
import playerbulletimg from '../../imgs/playerbullet.png'
import enemybulletimg from '../../imgs/enemybullet.png'

const Bullet = (props) => {
    const dispatch = useDispatch()
    const obj = props.object;
    let gameSpeed = useSelector((state) => state.game.gamespeed)
    const laser = useSelector((state => state.game.laser))
    if(laser) {
        gameSpeed = 4
    }
    let angle = 0
    if(obj.rotation && obj.rotation === 'left') {
        angle = 345
    }
    if(obj.rotation && obj.rotation === 'right') {
        angle = 15
    }

    useTick((delta) => {
        if(obj.type === 'player') {
            if(obj.y <= 0) {
                dispatch(deleteBullet({
                    'index': props.index,
                    'type': obj.type
            }))
            }
            else {
                if(obj.rotation === 'none') {
                    dispatch(updateBulletPosition({
                        'y':obj.y-8*gameSpeed,
                        'x': obj.x,
                        'width': obj.width,
                        'height': obj.height,
                        'type': obj.type,
                        'index': props.index,
                        'pierce': obj.pierce,
                        'rotation': obj.rotation
                    }))
                }
                if(obj.rotation === 'left') {
                    dispatch(updateBulletPosition({
                        'y':obj.y-8*gameSpeed,
                        'x': obj.x - 8*gameSpeed*Math.sin(Math.PI/12),
                        'width': obj.width,
                        'height': obj.height,
                        'type': obj.type,
                        'index': props.index,
                        'pierce': obj.pierce,
                        'rotation': obj.rotation
                    }))
                }
                if(obj.rotation === 'right') {
                    dispatch(updateBulletPosition({
                        'y':obj.y-8*gameSpeed,
                        'x': obj.x + 8*gameSpeed*Math.sin(Math.PI/12),
                        'width': obj.width,
                        'height': obj.height,
                        'type': obj.type,
                        'index': props.index,
                        'pierce': obj.pierce,
                        'rotation': obj.rotation
                    }))
                }
            }
        } else {
            if(obj.y >= 800) {
                dispatch(deleteBullet({
                    'index': props.index,
                    'type': obj.type
            }))
            }
            else {
                dispatch(updateBulletPosition({
                    'y':obj.y+2*(1+ (gameSpeed - 1)/4),
                    'x': obj.x,
                    'width': obj.width,
                    'height': obj.height,
                    'type': obj.type,
                    'index': props.index
                }
                    ))
            }
        }
    });

    const sprite = <Sprite x={obj.x} y={obj.y} anchor={{ x: 0.5, y: 0.5 }} image={obj.type === 'player' ? playerbulletimg : enemybulletimg} width={obj.width} height={laser ? 48 : obj.height} angle={angle}/>

    return (sprite)

}

export default Bullet
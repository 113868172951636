import React, {useCallback }from 'react'
import { Text, Graphics } from '@pixi/react';
import { useDispatch } from 'react-redux'
import '@pixi/events';
import { nextWave } from '../features/game'

const NewWaveButton = () => {

    const dispatch = useDispatch()
    const draw = useCallback((g) => {
        g.clear();
        g.lineStyle(2, '#a9a9a9', 1);
        g.beginFill('353535', 0.25);
        g.drawRoundedRect(500, 650, 200, 100, 750);
        g.endFill();
    }, []);
    const graphics = (
        <Graphics draw={draw} interactive={true} pointerdown={() => {dispatch(nextWave())}}>
            <Text text="Next Wave"
            anchor={0.5}
            x={600}
            y={700}
            style={({
                align: 'center',
                fontFamily: '"Source Sans Pro", Helvetica, sans-serif',
                fontSize: 25,
                fontWeight: '400',
                fill: ['#ff0000', '#a20000'],
                stroke: '#870303',
                strokeThickness: 2,
                dropShadow: true,
                dropShadowColor: '#8f0000',
                dropShadowBlur: 2,
                dropShadowAngle: Math.PI / 6,
                dropShadowDistance: 3,
                letterSpacing: 5,
                wordWrap: true,
                wordWrapWidth: 440,
                })}/>
        </Graphics>
    )

    return graphics
}

export default NewWaveButton
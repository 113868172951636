import React, { useState, useEffect, useRef } from 'react'
import { Sprite, useTick } from '@pixi/react';
import { useDispatch, useSelector } from 'react-redux'
import { updatePlayerPosition, playerShoot, updateEnemyPosition, addImmunity, removeImmunity } from '../features/game'
import player from '../../imgs/player.png'
import cannon from '../../audio/Space-Cannon.mp3'
import lasersound from '../../audio/Laser-Shot-3.mp3'

const Player = (props) => {
    const dispatch = useDispatch();
    const obj = props.object;
    const playerX = useSelector((state) => state.game.canvasPlayer[0].x);
    const gameSpeed = useSelector((state) => state.game.gamespeed);
    const roundWon = useSelector((state => state.game.roundWon))
    const lives = useSelector((state => state.game.lives))
    const [movingLeft, setMovingLeft] = useState(false);
    const [movingRight, setMovingRight] = useState(false);
    const [shooting, setShooting] = useState(false);
    const [canShoot, setCanShoot] = useState(false);
    const [canPlayLaserSound, setCanPlayLaserSound] = useState(true);
    const [uncontrolledCanMove, setUncontrolledCanMove] = useState(true);
    const gameLost = useSelector(state => state.game.gameLost);
    let atkSpeed = useSelector(state => state.game.atkSpeed);
    if(props.index !== 0) {
        atkSpeed = 1000
    }
    const laser = useSelector(state => state.game.laser);
    const iter = useRef(0);

    useEffect(() => {
        setCanShoot(true)
        if(obj.type === 'controlled') {
            window.addEventListener("keydown",(e => {keyDown(e)}))
            window.addEventListener("keyup",(e => {keyUp(e)}))
        } else if(obj.x > playerX) {
            setMovingRight(true)
        } else if(obj.x < playerX) {
            setMovingLeft(true)
        }
    },[])

    useEffect(() => {
        setCanShoot(true)
        if(obj.x > playerX) {
            setMovingRight(true)
        } else if(obj.x < playerX) {
            setMovingLeft(true)
        }
    },[roundWon])

    const keyDown = (e) => {
            if(e.key === 'ArrowRight') {
                setMovingRight(true);
            }
            if(e.key === 'ArrowLeft') {
                setMovingLeft(true);
            }
            if(e.key === ' ') {
                setShooting(true);
            }
    }

    const keyUp = (e) => {
        if(e.key === 'ArrowRight') {
            setMovingRight(false);
        }
        if(e.key === 'ArrowLeft') {
            setMovingLeft(false);
        }
        if(e.key === ' ') {
            setShooting(false);
        }
    }

    useEffect(() => {
        dispatch(addImmunity())
        const timer = setTimeout(() => {
            dispatch(removeImmunity())
            clearTimeout(timer)
        }, 1000);


    },[lives])

    useEffect(() => {
        if(obj.type !== 'controlled' && uncontrolledCanMove) {
            setUncontrolledCanMove(false)
            const currentX = obj.x
            const leftOrRight = Math.random()*800 < currentX ? setMovingLeft(true) : setMovingRight(true)
            setShooting(true)
            const timer = setTimeout(() => {
                setMovingLeft(false)
                setMovingRight(false)
                setShooting(false)
                setUncontrolledCanMove(true)
                clearTimeout(timer)
            }, 1000);
        }   
    },[movingLeft, movingRight])

    useTick(async (delta) => {
        if(!gameLost && obj.type === 'controlled' && !roundWon) {
            const prevVal = Math.sin(iter.current/5)*0.8;
            const i = (iter.current += 0.05 * gameSpeed);
            const sine = Math.sin(i/5)*0.8
            const yUpdate = (prevVal < 0 && sine > prevVal) || (prevVal > 0 && sine < prevVal) ? (((gameSpeed - 1)/3) + 1/5): 0
            dispatch(updateEnemyPosition({
                'x': sine,
                'y': yUpdate,
            }))
        }
        if(!gameLost) {
            if(movingLeft) {
                dispatch(updatePlayerPosition({
                    'x':-5*gameSpeed*delta,
                    'index': props.index,
                    'type': obj.type
                }))
            }
            if(movingRight) {
                dispatch(updatePlayerPosition({
                    'x':5*gameSpeed*delta,
                    'index': props.index,
                    'type': obj.type
                }))
            }
            if(shooting && canShoot && !(roundWon && obj.type !== 'controlled')) {
                if(!laser || obj.type !== 'controlled') {
                    await setCanShoot(false)
                    const playerCannon = new Audio(cannon)
                    playerCannon.volume = 0.3
                    playerCannon.play()
                    dispatch(playerShoot({
                        'index': props.index
                    }));
                    const timer = setTimeout(() => {
                        setCanShoot(true)
                        clearTimeout(timer)
                    }, atkSpeed);
                } else {
                    dispatch(playerShoot({
                        'index': props.index
                    }))
                    setCanPlayLaserSound(false)
                    if(canPlayLaserSound) {
                    const playerLaser = new Audio(lasersound)
                    playerLaser.volume = 0.3
                    playerLaser.play()
                    const timer = setTimeout(() => {
                        setCanPlayLaserSound(true)
                        clearTimeout(timer)
                    }, 500);
                    }
                }
            }
        }
    });

    const sprite = <Sprite x={obj.x} y={obj.y} anchor={{ x: 0.5, y: 0.5 }} image={player} height={obj.height} width={obj.width}/>

    return (sprite)

}

export default Player
import React, { useCallback } from 'react'
import '@pixi/events';
import { Stage, Text, Graphics } from '@pixi/react';
import { useDispatch } from 'react-redux'
import { startGame } from '../features/game'

const StartScreen = () => {
    const dispatch = useDispatch()
    const draw = useCallback((g) => {
        g.clear();
        g.lineStyle(2, '#a9a9a9', 1);
        g.beginFill('353535', 0.25);
        g.drawRoundedRect(200, 650, 400, 100, 750);
        g.endFill();
      }, []);
      const graphics = (
        <Graphics draw={draw} interactive={true} pointerdown={() => {dispatch(startGame())}}>
            <Text text="Start"
            anchor={0.5}
            x={400}
            y={700}
            style={({
                align: 'center',
                fontFamily: '"Source Sans Pro", Helvetica, sans-serif',
                fontSize: 25,
                fontWeight: '400',
                fill: ['#ff0000', '#a20000'], // gradient
                stroke: '#870303',
                strokeThickness: 2,
                dropShadow: true,
                dropShadowColor: '#8f0000',
                dropShadowBlur: 2,
                dropShadowAngle: Math.PI / 6,
                dropShadowDistance: 3,
                letterSpacing: 5,
                wordWrap: true,
                wordWrapWidth: 440,
                })}/>
        </Graphics>
    )

    return (
        <Stage height={800} width={800}>
            <Text
                text="
                Alien Invaders
                --------------------------------------------------
                Credits:
                --------------------------------------------------
                Programming by John Durtka
                --------------------------------------------------
                Music and Sound Effects by Eric Matyas
                www.soundimage.org
                --------------------------------------------------
                Most art assets (aliens, player) by Clear_code
                https://opengameart.org/users/clearcode
                --------------------------------------------------
                Game testing by Lilly, Livia
                "
                anchor={0.5}
                x={400}
                y={300}
                style={({
                    align: 'center',
                    fontFamily: '"Source Sans Pro", Helvetica, sans-serif',
                    fontSize: 18,
                    fontWeight: '400',
                    fill: ['#ff0000', '#a20000'], // gradient
                    stroke: '#870303',
                    strokeThickness: 2,
                    dropShadow: true,
                    dropShadowColor: '#8f0000',
                    dropShadowBlur: 2,
                    dropShadowAngle: Math.PI / 6,
                    dropShadowDistance: 3,
                    letterSpacing: 5,
                    wordWrap: true,
                    wordWrapWidth: 440,
                    })}/>
            {graphics}
        </Stage>
    )

}

export default StartScreen
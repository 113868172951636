import { createSlice } from '@reduxjs/toolkit'
import gameoverSound from '../../audio/PowerRez1.mp3'
import hullbreach3 from '../../audio/Hull-Breach-3.mp3'
import lasershot1 from '../../audio/Laser-Shot-1.mp3'

export const gameSlice = createSlice({
  name: 'game',
  initialState: {
    canvasEnemies: [],
    canvasPlayer: [{
        'x': 400,
        'y': 784,
        'width': 32,
        'height': 40,
        'type': 'controlled'
    }],
    canvasPlayerBullets: [],
    canvasEnemyBullets: [],
    newWaveAdded: false,
    score: 0,
    gamespeed: 3,
    lives: 3,
    gameLost: false,
    roundWon: false,
    gameStarted: false,
    highScore: 0,
    credits: 0,
    weaponDamage: 10,
    atkSpeed: 500,
    pierce: false,
    shotgun: false,
    laser: false,
    upgrades: [],
    immune: false,
  },
  reducers: {
    updateEnemyPosition: (state, action) => {
        const newCanvasEnemies = state.canvasEnemies
        newCanvasEnemies.map((newCanvasEnemy) => {
            newCanvasEnemy.y = newCanvasEnemy.y + action.payload.y;
            newCanvasEnemy.x = newCanvasEnemy.x + action.payload.x;
            if(newCanvasEnemy.y > 800) {
                state.lives = state.lives - 1
                if(state.lives <= 0) {
                    state.gameLost = true;
                    const gameOverSound = new Audio(gameoverSound);
                    gameOverSound.volume = 0.3;
                    gameOverSound.play();
                    state.gameHighScore = state.gameHighScore < state.score ? state.score : state.gameHighScore;
                    state.score = 0;
                }
            } 
        })
        if(!state.gameLost) {
            state.canvasEnemies = newCanvasEnemies;
        }
    },
    updatePlayerPosition: (state, action) => {
        const newCanvasPlayer = {
            'x': state.canvasPlayer[action.payload.index].x + action.payload.x,
            'y': state.canvasPlayer[action.payload.index].y,
            'width': 32,
            'height': 40,
            'type': action.payload.type
        }
        if(!(newCanvasPlayer.x > 800) && !(newCanvasPlayer.x < 0)) {
        state.canvasPlayer[action.payload.index] = newCanvasPlayer;
        }
    },
    playerShoot: (state, action) => {
        const index = action.payload.index
        if(!state.shotgun) {
        const newBullet = {
            'x': state.canvasPlayer[index].x,
            'y': state.canvasPlayer[index].y - 33,
            'width': 6,
            'height': 18,
            'type': 'player',
            'pierce': state.pierce,
            'rotation': 'none'
        }
        state.canvasPlayerBullets = [...state.canvasPlayerBullets, newBullet]
        } else {
            const newBullets = [{
                'x': state.canvasPlayer[index].x,
                'y': state.canvasPlayer[index].y - 33,
                'width': 6,
                'height': 18,
                'type': 'player',
                'pierce': state.pierce,
                'rotation': 'none'
            },
            {
                'x': state.canvasPlayer[index].x + 10,
                'y': state.canvasPlayer[index].y - 33,
                'width': 6,
                'height': 18,
                'type': 'player',
                'pierce': state.pierce,
                'rotation': 'right'
            },
            {
                'x': state.canvasPlayer[index].x - 10,
                'y': state.canvasPlayer[index].y - 33,
                'width': 4,
                'height': 12,
                'type': 'player',
                'pierce': state.pierce,
                'rotation': 'left'
            }]
            state.canvasPlayerBullets = [...state.canvasPlayerBullets, ...newBullets]
        }
          
    },
    updateBulletPosition: (state, action) => {
        let collision = false
        const newBullet = {
            'x': action.payload.x,
            'y': action.payload.y,
            'width': action.payload.width,
            'height': action.payload.height,
            'type': action.payload.type,
            'pierce': action.payload.pierce,
            'rotation': action.payload.rotation
        }
        if(action.payload.type ==='player') {
            if(!state.roundWon) {
                state.canvasEnemies.map((enemy, index) => {
                    if((enemy.x + enemy.size/2 > newBullet.x) &&
                    (enemy.x - enemy.size/2 < newBullet.x) &&
                    (enemy.y + enemy.size/2 > newBullet.y) &&
                    (enemy.y - enemy.size/2 < newBullet.y)
                    )   {
                        enemy.health = enemy.health - state.weaponDamage;
                        if(enemy.health <= 0) { 
                        const scoreValue = enemy.type === 'green' ? 30 : enemy.type === 'yellow' ? 20 : 10
                        const creditsValue = enemy.type === 'green' ? 2 + Math.floor(state.gamespeed*1.9 - 1) : enemy.type === 'yellow' ? 1 + Math.floor(state.gamespeed*1.9 - 1) : Math.floor(state.gamespeed*2 - 1)
                        state.canvasEnemies = [
                            ...state.canvasEnemies.slice(0, index),
                            ...state.canvasEnemies.slice(index + 1)
                        ]
                        if(!newBullet.pierce) {
                            state.canvasPlayerBullets = [
                                ...state.canvasPlayerBullets.slice(0, action.payload.index),
                                ...state.canvasPlayerBullets.slice(action.payload.index + 1)
                            ]
                        }
                        const newScore = state.score + scoreValue;
                        state.score = newScore;
                        state.credits = state.credits + creditsValue
                        if(state.canvasEnemies.length === 0) {
                            state.roundWon = true;
                            state.newWaveAdded = false;
                        }
                    }
                        collision = true;
                    }
                })
                if(!collision) {
                    state.canvasPlayerBullets = [
                        ...state.canvasPlayerBullets.slice(0, action.payload.index),
                        newBullet,
                        ...state.canvasPlayerBullets.slice(action.payload.index + 1)
                    ]
                }
            } else {
                state.upgrades.map((upgrade, index) => {
                    if((upgrade.x + upgrade.size/2 > newBullet.x) &&
                    (upgrade.x - upgrade.size/2 < newBullet.x) &&
                    (upgrade.y + upgrade.size/2 > newBullet.y) &&
                    (upgrade.y - upgrade.size/2 < newBullet.y)) {
                        collision = true;
                        state.canvasPlayerBullets = [
                            ...state.canvasPlayerBullets.slice(0, action.payload.index),
                            ...state.canvasPlayerBullets.slice(action.payload.index + 1)
                        ];
                        if(upgrade.text === '+Atk Spd' && state.credits >= upgrade.cost) {
                            state.atkSpeed = state.atkSpeed - 100;
                            state.credits = state.credits - upgrade.cost;
                            state.upgrades = [
                                ...state.upgrades.slice(0, index),
                                ...state.upgrades.slice(index + 1)
                            ];
                        }
                        if(upgrade.text === '+Pierce' && state.credits >= upgrade.cost) {
                            state.pierce = true;
                            state.credits = state.credits - upgrade.cost;
                            state.upgrades = [
                                ...state.upgrades.slice(0, index),
                                ...state.upgrades.slice(index + 1)
                            ];
                        }
                        if(upgrade.text === 'Shotgun' && state.credits >= upgrade.cost) {
                            state.shotgun = true;
                            state.credits = state.credits - upgrade.cost;
                            state.upgrades = [
                                ...state.upgrades.slice(0, index),
                                ...state.upgrades.slice(index + 1)
                            ];
                        }
                        if(upgrade.text === 'Laser' && state.credits >= upgrade.cost) {
                            state.laser = true;
                            state.weaponDamage = 1;
                            state.credits = state.credits - upgrade.cost;
                            state.upgrades = [
                                ...state.upgrades.slice(0, index),
                                ...state.upgrades.slice(index + 1)
                            ];
                        }
                        if(upgrade.text === '+Dmg' && state.credits >= upgrade.cost) {
                            state.weaponDamage = state.weaponDamage*2;
                            state.credits = state.credits - upgrade.cost;
                            state.upgrades = [
                                ...state.upgrades.slice(0, index),
                                ...state.upgrades.slice(index + 1)
                            ];
                        }
                        if(upgrade.text === 'Helper' && state.credits >= upgrade.cost) {
                            const helper = state.canvasPlayer.length > 2 ? {
                                'x': 350,
                                'y': 784,
                                'width': 32,
                                'height': 40,
                                'type': 'uncontrolled'
                            } :
                            {
                                'x': 450,
                                'y': 784,
                                'width': 32,
                                'height': 40,
                                'type': 'uncontrolled'
                            }
                            const newPlayers = [
                                ...state.canvasPlayer.slice(0,state.canvasPlayer.length),
                                helper];
                            state.canvasPlayer = newPlayers;
                            state.credits = state.credits - upgrade.cost;
                            state.upgrades = [
                                ...state.upgrades.slice(0, index),
                                ...state.upgrades.slice(index + 1)
                            ];

                        }
                    }
                })
                if(!collision) {
                    state.canvasPlayerBullets = [
                        ...state.canvasPlayerBullets.slice(0, action.payload.index),
                        newBullet,
                        ...state.canvasPlayerBullets.slice(action.payload.index + 1)
                    ]
                }
            }
        } else {
            if((state.canvasPlayer[0].x + state.canvasPlayer[0].width/2 > newBullet.x) &&
                    (state.canvasPlayer[0].x - state.canvasPlayer[0].width/2 < newBullet.x) &&
                    (state.canvasPlayer[0].y - Number(state.canvasPlayer[0].height/2) < newBullet.y && !state.immune)) {
                        console.log(state.immune)
                        state.canvasEnemyBullets = [
                            ...state.canvasEnemyBullets.slice(0, action.payload.index),
                            ...state.canvasEnemyBullets.slice(action.payload.index + 1)
                        ]
                        collision = true;
                        const damagedSound = new Audio(hullbreach3);
                        damagedSound.volume = 0.3;
                        damagedSound.play();
                        state.lives = state.lives - 1
                        if(state.lives === 0) {
                            state.gameLost = true;
                            const gameOverSound = new Audio(gameoverSound)
                            gameOverSound.volume = 0.3
                            gameOverSound.play();
                            state.gameHighScore = state.gameHighScore < state.score ? state.score : state.gameHighScore;
                        }
                    }

            if(!collision) {
                state.canvasEnemyBullets = [
                    ...state.canvasEnemyBullets.slice(0, action.payload.index),
                    newBullet,
                    ...state.canvasEnemyBullets.slice(action.payload.index + 1)
                ]
            }

        }
    },
    deleteBullet: (state, action) => {
        if(action.payload.type === 'player') {
            const newBullets = [
                ...state.canvasPlayerBullets.slice(0, action.payload.index),
            ]
            state.canvasPlayerBullets = newBullets
        } else {
            const newBullets = [
                ...state.canvasEnemyBullets.slice(0, action.payload.index),
            ]
            state.canvasEnemyBullets = newBullets
        }
    },
    enemyShoot: (state) => {
        if(state.canvasEnemies.length > 0) {
            const selectRandomEnemy = () => {
            if(state.canvasEnemies.length > 11) {
                const ind = Math.floor(Math.random()*11)
                return ind
            } else {
                const ind = Math.floor(Math.random()*state.canvasEnemies.length)
                return ind
            }
            }
            const ind = selectRandomEnemy();
            let selectedEnemy = state.canvasEnemies[ind];
            state.canvasEnemies.map((enemy) => {
                if(selectedEnemy.x === enemy.x &&
                    selectedEnemy.y < enemy.y) {
                        selectedEnemy = enemy
                    }
            })
            const newBullet = {
                'x': selectedEnemy.x,
                'y': selectedEnemy.y + 33,
                'width': 8,
                'height': 24,
                'type': 'enemy'
            }
            const enemyLaser = new Audio(lasershot1)
            enemyLaser.volume=0.3
            enemyLaser.play()
            state.canvasEnemyBullets = [...state.canvasEnemyBullets, newBullet]
        }
    },
    startGame: (state) => {
        state.canvasEnemies = [];
        state.canvasPlayer = [{
                'x': 400,
                'y': 784,
                'width': 32,
                'height': 40,
                'type': 'controlled'
            }];
        state.canvasEnemyBullets = [];
        state.score = 0;
        state.gamespeed = 1;
        state.newWaveAdded = true;
        state.lives = 3;
        state.gameLost = false;
        state.roundWon = false;
        state.gameStarted = true;
        state.credits = 0;
        state.weaponDamage = 10;
        state.atkSpeed = 500;
        state.pierce = false;
        state.shotgun = false;
        state.laser = false;
        state.immune = false;
        state.upgrades = [{
                'y': 600,
                'x': 75,
                'size': 32,
                'type': 'red',
                'upgrade': true,
                'cost': 50,
                'text': '+Atk Spd'
            },
            {
                'y': 400,
                'x': 75,
                'size': 32,
                'type': 'yellow',
                'upgrade': true,
                'cost': 100,
                'text': '+Atk Spd'
            },
            {
                'y': 200,
                'x': 75,
                'size': 32,
                'type': 'green',
                'upgrade': true,
                'cost': 150,
                'text': '+Atk Spd'
            },
            {
                'y': 200,
                'x': 225,
                'size': 32,
                'type': 'green',
                'upgrade': true,
                'cost': 200,
                'text': '+Pierce'
            },
            {
                'y': 200,
                'x': 375,
                'size': 32,
                'type': 'green',
                'upgrade': true,
                'cost': 150,
                'text': 'Shotgun'
            },
            {
                'y': 400,
                'x': 525,
                'size': 32,
                'type': 'yellow',
                'upgrade': true,
                'cost': 150,
                'text': 'Laser'
            },
            {
                'y': 200,
                'x': 525,
                'size': 32,
                'type': 'green',
                'upgrade': true,
                'cost': 300,
                'text': '+Dmg'
            },
            {
                'y': 400,
                'x': 675,
                'size': 32,
                'type': 'yellow',
                'upgrade': true,
                'cost': 250,
                'text': 'Helper'
            },
            {
                'y': 200,
                'x': 675,
                'size': 32,
                'type': 'green',
                'upgrade': true,
                'cost': 300,
                'text': 'Helper'
            }];
            const newCanvasEnemies = []
        for(let rowidx = 0; rowidx < 4 + Math.floor(state.gamespeed - 0.4); rowidx++) {
            for(let colidx = 0; colidx < 11; colidx++) {
                newCanvasEnemies.push({
                    'y': -250 + Math.floor(50*rowidx),
                    'x': 50 + Math.floor(54.54*colidx),
                    'size': 32,
                    'type': rowidx === 0 ? 'green' : rowidx < 2 ? 'yellow' : 'red',
                    'upgrade': false,
                    'health': 10,
                })
            }
        }
        state.canvasEnemies = newCanvasEnemies
        state.newWaveAdded = true;
    },
    nextWave: (state) => {
        state.roundWon = false;
        state.gamespeed = state.gamespeed + 0.2
        const newCanvasEnemies = []
        const health = !state.laser ? 10 : 10 + Math.floor(state.gamespeed - 1)*4
        for(let rowidx = 0; rowidx < 4 + Math.floor(state.gamespeed - 1); rowidx++) {
            for(let colidx = 0; colidx < 11; colidx++) {
                newCanvasEnemies.push({
                    'y': -250 + Math.floor(50*rowidx),
                    'x': 50 + Math.floor(54.54*colidx),
                    'size': 32,
                    'type': rowidx === 0 ? 'green' : rowidx < 2 ? 'yellow' : 'red',
                    'upgrade': false,
                    'health': health
                })
            }
        }
        state.canvasEnemies = newCanvasEnemies
        state.newWaveAdded = true;
    },
    addImmunity: (state) => {
        state.immune = true;
    },
    removeImmunity: (state) => {
        state.immune = false;
    },
  }
})

// Action creators are generated for each case reducer function
export const { removeEnemy, addNewWave, updateEnemyPosition, updatePlayerPosition, playerShoot, updateBulletPosition, deleteBullet, enemyShoot, startGame, nextWave, addImmunity, removeImmunity } = gameSlice.actions

export default gameSlice.reducer
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Stage, Text } from '@pixi/react';
import store from '../app/store'
import { Provider } from 'react-redux'
import { startGame, enemyShoot } from '../features/game'
import SpaceInvader from './spaceInvader.js'
import Player from './player.js'
import Bullet from './bullet.js'
import Counter from './counter.js'
import NewWaveButton from './newWaveButton.js'
import RestartButton from './RestartButton.js'
import song from '../../audio/Light-Years_V001_Looping.mp3'
import hullbreach4 from '../../audio/Hull-Breach-4.mp3'

const Game = () => {
    const dispatch = useDispatch()
    const [enemyCanShoot, setEnemyCanShoot] = useState(true)
    const [canPlaySound, setCanPlaySound] = useState(true);
    const gameLost = useSelector(state => state.game.gameLost)
    const roundWon = useSelector(state => state.game.roundWon)
    const canvasEnemies = useSelector(state => state.game.canvasEnemies)
    const gameStarted = useSelector(state => state.game.gameStarted)
    const waveAdded = useSelector(state => state.game.newWaveAdded)
    const gameScore = String(useSelector(state => state.game.score))
    const gameCredits = String(useSelector(state => state.game.credits))
    const gameHighScore = String(useSelector(state => state.game.highScore))
    const lives = String(useSelector(state => state.game.lives))
    const scoreStr = 'Score: ' + gameScore
    const creditsStr = 'Credits: ' + gameCredits
    const highscoreStr = 'Highscore: ' + gameHighScore
    const livesStr = 'Lives: ' + lives
    
  
    useEffect(() => {
      dispatch(startGame())
      const audio = new Audio(song);
      audio.volume = 0.2;
      audio.loop = true;
      audio.play();
      },[])
  
    useEffect(() => {
      setEnemyCanShoot(false)
      const timer = setTimeout(
        () => {
          if(!gameLost && !roundWon) {
            dispatch(enemyShoot())
            setEnemyCanShoot(true)
          }
        },
        2000
      );
      return () => clearTimeout(timer);
  
    },[enemyCanShoot,roundWon]);

    useEffect(() => {
      if(waveAdded && canPlaySound) {
        setCanPlaySound(false)
        const enemyDestroyedSound = new Audio(hullbreach4)
        enemyDestroyedSound.volume = 0.3
        enemyDestroyedSound.play()
        const timer = setTimeout(
          () => {
              setCanPlaySound(true)
            },
          2000);
        return () => clearTimeout(timer);
      }

    },[canvasEnemies.length])

    const enemies = canvasEnemies.map((obj, index) => {
        if(obj !== undefined) {
          return <SpaceInvader object={obj} index={index}/>
        } 
      })
    
      const player = useSelector(state => state.game.canvasPlayer).map((obj, index) => {
        return <Player object={obj} index={index}/>
      })
    
      const playerBullets = useSelector(state => state.game.canvasPlayerBullets).map((obj, index) => {
        return <Bullet object={obj} index={index}/>
      }
      )
    
      const enemyBullets = useSelector(state => state.game.canvasEnemyBullets).map((obj, index) => {
        return <Bullet object={obj} index={index}/>
      })

      const upgrades = useSelector(state => state.game.upgrades).map((obj, index) => {
        return <SpaceInvader object={obj} index={index} />
      })

    return (
        <div>
            {gameStarted && !gameLost && !roundWon ?
              <Stage width={800} height={800} interactive={'auto'}>
                <Provider store={store}>
                  <Counter value={scoreStr} x={50} y={50} />
                  <Counter value={highscoreStr} x={300} y={50} />
                  <Counter value={creditsStr} x={550} y={50} />
                  <Counter value={livesStr} x={0} y={750} />
                  {enemies.map((enemy) => enemy)}
                  {player.map((player) => player)}
                  {playerBullets.map((bullet) => bullet)}
                  {enemyBullets.map((bullet) => bullet)}
                </Provider>
              </Stage> :
            roundWon ? 
              <Stage width={800} height={800} interactive={'auto'}>
                <Provider store={store}>
                  <Counter value={scoreStr} x={50} y={50} />
                  <Counter value={highscoreStr} x={300} y={50} />
                  <Counter value={creditsStr} x={550} y={50} />
                  {player.map((player) => player)}
                  {upgrades.map((upgrade) => upgrade)}
                  {playerBullets.map((bullet) => bullet)}
                  <NewWaveButton/>
                </Provider>
              </Stage> :
            gameLost ?
              <Stage width={800} height={800} interactive={'auto'}>
                <Provider store={store}>
                  {enemies.map((enemy) => enemy)}
                  {player.map((player) => player)}
                  {playerBullets.map((bullet) => bullet)}
                  {enemyBullets.map((bullet) => bullet)}
                  <Text
                    text="Game Over!"
                    anchor={0.5}
                    x={400}
                    y={400}
                    style={({
                        align: 'center',
                        fontFamily: '"Source Sans Pro", Helvetica, sans-serif',
                        fontSize: 50,
                        fontWeight: '400',
                        fill: ['#ff0000', '#a20000'], // gradient
                        stroke: '#870303',
                        strokeThickness: 2,
                        letterSpacing: 20,
                        dropShadow: true,
                        dropShadowColor: '#8f0000',
                        dropShadowBlur: 4,
                        dropShadowAngle: Math.PI / 6,
                        dropShadowDistance: 6,
                        wordWrap: true,
                        wordWrapWidth: 440,
                      })}/>
                      <RestartButton />
                </Provider>
              </Stage> 
            : ''
          }
        </div>
      );
    
}

export default Game;
